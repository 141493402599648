/**
 * @file ReportsForm.tsx component for the form to request an XLSX report
 * @author Christian Vasold
 * @exports React.Component
 */
import { useState } from "react";
import { Grid, Button, FormHelperText, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useStyles from "./style";
import RadioSelect from "../../common/templates/forms/RadioSelect/RadioSelect";
import { Formik, Form, FormikValues, Field } from "formik";
import { validationSchema } from "./validationSchema";
import reportService, { ReportData } from "../../../services/reportService";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import { extractErrorMessage } from "../../../utils/utils";
import { setField } from "../../../utils/validationUtils";
import * as Excel from "exceljs";

/**
 * Properties to pass to the component
 */
interface Props {
    partnerId?: string;
    brandId?: string;
}

/**
 * Parameters for the backend call
 */
export type ApiCallParams = {
    from: string;
    to: string;
    type: string;
    levelOfDetail: ReportLevelOfDetail;
    callAnalytics: boolean;
    id?: string;
    excludeReferenceCampaigns?: boolean;
}

/** 
 * Specifies the amount of information in the report
 */
export enum ReportLevelOfDetail {
    /** 
     * The report will contain only a single line (total count) per day. Or a single line at all for the aggregate report
     */
    GLOBAL = "global",
    /** 
     * The report will contain the total number of calls only on the "partner" level
     */
    PARTNERS = "partners",
    /** 
     * The report will contain the total number of calls on the "partner" and "brand" levels
     */
    BRANDS = "brands",
    /** 
     * The report will contain the total number of calls on the "partner", "brand" and "campaign" levels
     */
    CAMPAIGNS = "campaigns",
    /** 
     * The report will contain an additional "phone number" level compared to the Campaigns report
     */
    NUMBERS = "numbers"
}

/** 
 * Converts a date into an ISO date string like 2023-06-23,
 * using the local time of the date.
 */
export function toIsoDate(date: Date): string {
    return date.getFullYear() + "-"
        + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
        + ("0" + date.getDate()).slice(-2);
}

/** 
 * Return file name, report scope, brand alias and partner alias.
 */
function getFileName(reportData: ReportData, params: ApiCallParams, props: Props): [string, string, string|undefined, string|undefined] {
    // "report_" scope "_" [level-of-detail "_"] type "_" from "_" to "." extension
    let fileName = "report_";
    // To be used in the workbook description.
    let scope = "";
    let brandAlias;
    let partnerAlias;
    // level-of-detail is only included if it is is finer than the scope.
    // We want to avoid names containing e.g. "global_global" or "brand-ACME_brands".
    let levelToNotBeIncluded;
    if (props.partnerId) {
        scope = "Partner";
        levelToNotBeIncluded = ReportLevelOfDetail.PARTNERS;
        partnerAlias = reportData.rows[0].partnerAlias;
        fileName += "partner-" + partnerAlias + "_";
    } else if (props.brandId) {
        scope = "Brand";
        levelToNotBeIncluded = ReportLevelOfDetail.BRANDS;
        brandAlias = reportData.rows[0].brandAlias;
        fileName += "brand-" + brandAlias + "_";
    } else {
        scope = "Global";
        levelToNotBeIncluded = ReportLevelOfDetail.GLOBAL;
        fileName += "global_";
    }
    const fileNameLevelOfDetail = params.levelOfDetail !== levelToNotBeIncluded ? params.levelOfDetail + "_" : "";
    fileName += fileNameLevelOfDetail + params.type + "_" + params.from + "_" + params.to + ".xlsx";

    return [fileName, scope, brandAlias, partnerAlias];
}

async function downloadWorkbook(fileName: string, workbook: Excel.Workbook) {
    const output = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([output]));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
}

/**
 * Map from column data type to Excel format that 
 * should be applied to the data contained in the column.
 */
const excelFormatMap = new Map([
    ["percentage", "0.00%"],
    ["decimal", "0.00"],
    ["date", "yyyy-mm-dd"]
]);

async function generateAndDownloadXlsxFile(reportData: ReportData, params: ApiCallParams, props: Props) {
    const [fileName, scope, brandAlias, partnerAlias] = getFileName(reportData, params, props);
    const scopeString = brandAlias 
        ? "Brand " + brandAlias 
        : partnerAlias
            ? "Partner " + partnerAlias
            : "global scope";

    const workbook = new Excel.Workbook();
    workbook.title = scope + " Report (" + params.from + " - " + params.to + ")";
    workbook.description =
        "Report of type " + params.type + " generated for " + scopeString + " and level of detail " + params.levelOfDetail +
        ". Showing data from " + params.from + " to " + params.to +
        ". Call analytics are " + (params.callAnalytics ? "" : "not ") + "included.";
    workbook.creator = "CallerID";
    workbook.lastModifiedBy = "CallerID";
    workbook.created = new Date();

    const reportWorksheet: Excel.Worksheet = workbook.addWorksheet("Report")!;
    reportWorksheet.columns = reportData.headers.map(header => {
        let format;
        if (header.dataType && excelFormatMap.has(header.dataType)) {
            format = excelFormatMap.get(header.dataType);
        }
        return {
            header: header.label,
            key: header.key,
            style: { numFmt: format },
            width: header.width || 15
        };
    });
    reportWorksheet.addRows(reportData.rows.map(row => {
        // We need to convert string into date to apply the desired date format to the column.
        return {
            ...row,
            date: row.date ? new Date(row.date) : undefined
        };
    }));

    await downloadWorkbook(fileName, workbook);
}

/**
 * Reports form component. Shows a UI to select start date, 
 * end date and type of the report and a button to fetch 
 * the data from the server which in turn will generate an XLSX file to download.
 */
export default function ReportsForm(props: Props) {

    const getAllReports = (vals: ApiCallParams) => reportService.getAll(vals);
    const getPartnerReports = (vals: ApiCallParams) => reportService.getByPartnerId(vals);
    const getBrandReports = (vals: ApiCallParams) => reportService.getByBrandId(vals);

    const classes = useStyles();

    /** Error information for the snackback */
    const [error, setError] = useState(false);
    /** The error message for the snackback */
    const [errorMsg, setErrorMsg] = useState<string>("");

    let reportLevelOptions: { label: string, value: ReportLevelOfDetail}[] = [];
    if (!props.brandId) {
      if (!props.partnerId) {
        reportLevelOptions.push({ label: "Global", value: ReportLevelOfDetail.GLOBAL });
      }
      reportLevelOptions.push({ label: "Partners", value: ReportLevelOfDetail.PARTNERS });
    }
    reportLevelOptions.push({ label: "Brands", value: ReportLevelOfDetail.BRANDS });
    reportLevelOptions.push({ label: "Campaigns", value: ReportLevelOfDetail.CAMPAIGNS });
    reportLevelOptions.push({ label: "Numbers", value: ReportLevelOfDetail.NUMBERS });

    /**
     * Handles submission of the form to generate the report. Calls the backend, generates the XLSX file and makes the browser download it.
     */
    // TODO we could/should move this to the page using the component, along with the mutation functions
    async function handleRequestReport(values: FormikValues) {

        // REST parameters for our backend call
        const params: ApiCallParams = {
            from: "",
            to: "",
            type: values.type,
            levelOfDetail: values.levelOfDetail,
            callAnalytics: values.callAnalytics
        };

        // From the datepicker we get a unix timestamp. However, the user has selected the day that they want the report for in their local timezone.
        // So we need to get the local day/month/year from the date, pad with zeroes and format into an ISO string
        params.from = toIsoDate(new Date(values.from))
        params.to = toIsoDate(new Date(values.to));

        let backendCall: (params: ApiCallParams) => Promise<ReportData> = getAllReports;

        if (props.partnerId) {
            // get the report for a specific partner
            params.id = props.partnerId;
            backendCall = getPartnerReports;
        } else if (props.brandId) {
            // get the report for a specific brand
            params.id = props.brandId;
            backendCall = getBrandReports;
        }

        // get the admin report for everything
        backendCall(params).then((output) => {
            // Sometimes, we won't get results from the DB
            if (!output || output.rows.length === 0) {
                setErrorMsg("There is no reporting data for the specified dates")
                setError(true);
            } else {
                generateAndDownloadXlsxFile(output, params, props);
            }
        }).catch((err) => {
            setErrorMsg(extractErrorMessage(err))
            setError(true);
        });
    }

    return (
        <>
            <Formik
                validationSchema={validationSchema}
                validateOnChange={true}
                initialValues={{
                    from: "",
                    to: "",
                    type: "",
                    levelOfDetail: "",
                    callAnalytics: false
                }}
                onSubmit={(values: FormikValues) => {
                    handleRequestReport(values);
                }}
            >
                {({ errors, values, touched, setFieldValue, setFieldTouched }) => (
                    <Form>
                        <div className={classes.marginStyle}></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <DatePicker
                                        label="Start date"
                                        value={values.from}
                                        onChange={(v) => setField("from", v!, setFieldValue, setFieldTouched)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DatePicker
                                        className="datePicker"
                                        label="End date"
                                        value={values.to}
                                        onChange={(v) => setField("to", v!, setFieldValue, setFieldTouched)} />
                                </Grid>
                            </Grid>
                            {errors.from && touched.from && (
                                <FormHelperText error={true}>
                                    {errors.from}
                                </FormHelperText>
                            )}
                            {errors.to && touched.to && (
                                <FormHelperText error={true}>
                                    {errors.to}
                                </FormHelperText>
                            )}
                        </LocalizationProvider>
                        <div className={classes.marginStyle}></div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography fontWeight="bold">Level of detail</Typography>
                                <RadioSelect
                                    name="levelOfDetail"
                                    options={reportLevelOptions}
                                />
                                {errors.levelOfDetail && touched.levelOfDetail && (
                                    <FormHelperText error={true}>
                                        Please select the level of detail
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontWeight="bold">Type</Typography>
                                <RadioSelect
                                    name="type"
                                    options={[
                                        { label: "Aggregate", value: "aggregate" },
                                        { label: "Raw", value: "raw" },
                                    ]}
                                />
                                {errors.type && touched.type && (
                                    <FormHelperText error={true}>
                                        Please choose a report type
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    type="checkbox"
                                    name="callAnalytics"
                                    as={FormControlLabel}
                                    control={<Checkbox />}
                                    label="Include call analytics"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            Request Report
                        </Button>
                    </Form>
                )}
            </Formik>
            <AlertSnackback
                message={errorMsg}
                type="error"
                open={error}
                setOpen={setError}
            />
        </>
    );
}
