/**
 * @file VFAdminList.tsx
 * @description Defines Navigation list items for Vodafone Admin role.
 * @author Harry Rhodes
 * @exports React.Fragment
 */
import { useState } from "react";
import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import BusinessIcon from "@mui/icons-material/Business";
import ListIcon from "@mui/icons-material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import RouterIcon from "@mui/icons-material/Router";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import PeopleIcon from "@mui/icons-material/People";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import useStyles from "../common/styles";
import { getDashboardMenuTitle, isAggregatorsFeatureEnabled, } from "../../../../../utils/featureToggle";
import { isApprovalsFeatureEnabled } from "../../../../../utils/featureToggle";
import { downloadHandbook } from "../../../../../utils/utils";
import { LibraryBooks, MenuBook } from "@mui/icons-material";

/**
 * Props
 * @typedef {{opcoId: string}} Props
 */
interface Props {
  opcoId: string;
}
/**
 * Renders OPCOAdminList
 * @param props component props @see Props
 * @returns {React.Component} VFAdminList
 */
export default function OPCOAdminList(props: Props) {
  const [openPartners, setOpenPartners] = useState(true);
  const [openAggregators, setOpenAggregators] = useState(true);
  const [openApprovals, setOpenApprovals] = useState(true);
  const [openHelp, setOpenHelp] = useState(true);
  const classes = useStyles();
  const handlePartnersClick = () => {
    setOpenPartners(!openPartners);
  };
  const handleAggregatorsClick = () => {
    setOpenAggregators(!openAggregators);
  };
  const handleApprovalsClick = () => {
    setOpenApprovals(!openApprovals);
  };
  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton component="a" href={"/opcoInfo"}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="OPCO Info" />
      </ListItemButton>
      <ListItemButton onClick={handlePartnersClick}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Partners" />
        {openPartners ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={openPartners} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/partners"}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="View all" />
          </ListItemButton>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/partners/manage"}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Manage" />
          </ListItemButton>
        </List>
      </Collapse>
      {isAggregatorsFeatureEnabled() &&
        <ListItemButton onClick={handleAggregatorsClick}>
          <ListItemIcon>
            <RouterIcon />
          </ListItemIcon>
          <ListItemText primary="Aggregators" />
          {openAggregators ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      }
      {isAggregatorsFeatureEnabled() &&
        <Collapse in={openAggregators} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/aggregators"}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="View all" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/aggregators/assigned"}
            >
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Assigned" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/aggregators/manage"}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Manage" />
            </ListItemButton>
          </List>
        </Collapse>
      }
      {isApprovalsFeatureEnabled() &&
        <ListItemButton onClick={handleApprovalsClick}>
          <ListItemIcon>
            <DomainVerificationIcon />
          </ListItemIcon>
          <ListItemText primary="Approvals" />
          {openApprovals ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      }
      {isApprovalsFeatureEnabled() &&
        <Collapse in={openApprovals} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/approvals"}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="View all" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/approvals/approved"}
            >
              <ListItemIcon>
                <MobileFriendlyIcon />
              </ListItemIcon>
              <ListItemText primary="Approved" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/approvals/pending"}
            >
              <ListItemIcon>
                <SmartphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Pending" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/approvals/rejected"}
            >
              <ListItemIcon>
                <PhonelinkEraseIcon />
              </ListItemIcon>
              <ListItemText primary="Rejected" />
            </ListItemButton>
          </List>
        </Collapse>}
      <ListItemButton component="a" href={"/users"}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>

      <ListItemButton onClick={() => setOpenHelp(!openHelp)}>
        <ListItemIcon>
          <LibraryBooks />
        </ListItemIcon>
        <ListItemText primary="Help" />
        {openHelp ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openHelp} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          <ListItemButton
            component="a"
            className={classes.nested}
            onClick={downloadHandbook}
          >
            <ListItemIcon>
              <MenuBook />
            </ListItemIcon>
            <ListItemText primary="Handbook" />
          </ListItemButton>

        </List>
      </Collapse>

    </List>
  );
}
