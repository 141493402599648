/**
 * @file VFAdminList.tsx
 * @description Defines Navigation list items for Vodafone Admin role.
 * @author Harry Rhodes
 * @exports React.Fragment
 */
import { useState } from "react";
import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PublicIcon from "@mui/icons-material/Public";
import BusinessIcon from "@mui/icons-material/Business";
import ListIcon from "@mui/icons-material/List";
import RouterIcon from "@mui/icons-material/Router";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import AssessmentIcon from "@mui/icons-material/Assessment";
import useStyles from "../common/styles";
import { getDashboardMenuTitle, isAggregatorsFeatureEnabled } from "../../../../../utils/featureToggle";
import { downloadHandbook } from "../../../../../utils/utils";
import { LibraryBooks, MenuBook } from "@mui/icons-material";

/**
 * Renders VFAdminList
 * @param props component props @see Props
 * @returns {React.Component} VFAdminList
 */
export default function VFAdminList() {
  const [openPartners, setOpenPartners] = useState(true);
  const [openAggregators, setOpenAggregators] = useState(true);
  const [openOPCOs, setOpenOPCOs] = useState(true);
  const [openHelp, setOpenHelp] = useState(true);
  const classes = useStyles();
  const handlePartnersClick = () => {
    setOpenPartners(!openPartners);
  };
  const handleOPCOClick = () => {
    setOpenOPCOs(!openOPCOs);
  };
  const handleAggregatorsClick = () => {
    setOpenAggregators(!openAggregators);
  };

  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton onClick={handleOPCOClick}>
        <ListItemIcon>
          <PublicIcon />
        </ListItemIcon>
        <ListItemText primary="OPCOs" />
        {openOPCOs ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOPCOs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/opcos"}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="View all" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={handlePartnersClick}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Partners" />
        {openPartners ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={openPartners} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/partners"}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="View all" />
          </ListItemButton>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/reports"}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </List>
      </Collapse>

      {isAggregatorsFeatureEnabled() &&
        <ListItemButton onClick={handleAggregatorsClick}>
          <ListItemIcon>
            <RouterIcon />
          </ListItemIcon>
          <ListItemText primary="Aggregators" />
          {openAggregators ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      }
      {isAggregatorsFeatureEnabled() &&
        <Collapse in={openAggregators} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/aggregators"}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="View all" />
            </ListItemButton>
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/aggregators/onboard"}
            >
              <ListItemIcon>
                <LibraryAddIcon />
              </ListItemIcon>
              <ListItemText primary="Onboard New" />
            </ListItemButton>
          </List>
        </Collapse>
      }
      <ListItemButton component="a" href={"/users"}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>

      <ListItemButton onClick={() => setOpenHelp(!openHelp)}>
        <ListItemIcon>
          <LibraryBooks />
        </ListItemIcon>
        <ListItemText primary="Help" />
        {openHelp ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openHelp} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          <ListItemButton
            component="a"
            className={classes.nested}
            onClick={downloadHandbook}
          >
            <ListItemIcon>
              <MenuBook />
            </ListItemIcon>
            <ListItemText primary="Handbook" />
          </ListItemButton>

        </List>
      </Collapse>

    </List>
  );
}
