/**
 * @file styles.ts Defines CSS styles for SignIn Page
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import backgroundImg from "./Assets/background.jpg";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  graySquare: {
    // hide the whole thing on mobile
    '@media (max-width: 500px)': {
      display: 'none',
    },
    padding: theme.spacing(2, 4, 2, 2),
    margin: theme.spacing(2, 3),
    backgroundColor: "#54575a",
    border: "1px solid #54575a"
  },
  introText: {
    marginTop: "2%",
    marginBottom: "2%",
    marginRight: "22%",
  },
  smartphoneImg: {
    marginTop: "-27%",
    marginRight: "2%",
    marginBottom: "-4%",
    float: "right",
    width: "20%",
  },
  whiteSquare: {
    padding: theme.spacing(2, 4, 2, 2),
    margin: theme.spacing(0, 0),
    backgroundColor: "#ffffff",
    border: "1px solid #54575a",
  },
  svgContainer: {
    margin: theme.spacing(1.5, 0),
  },
  svgIcon: {
    fontSize: 50
  },
  svgContentTitle: {
    fontWeight: "bold"
  },
  wrapper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stepper: {
    padding: "0px",
  },
  paper: {
    padding: theme.spacing(4, 4, 2, 4),
    margin: theme.spacing(0, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    boxShadow: "0px 2.5px 5px 0px #949698",
    minHeight: "50vh",
  },
  legalbanner: {
    padding: theme.spacing(2, 4, 2, 2),
    margin: theme.spacing(1, 1),
    backgroundColor: "#FFF484",
    border: "1px solid #DCC600",
  },
  avatar: {
    margin: theme.spacing(1, 1, 1, 0),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgot: {
    cursor: "pointer",
  }
}));

export default useStyles;
